<template>
  <span @click="handleLogoClick">
    <span v-if="isDark">
      <img
        v-if="monogram"
        class="logo logo--monogram"
        src="@/assets/images/monogram-dark.svg"
        :alt="`${t('company.name')} logo.`"
      />
      <img
        v-else
        class="logo"
        src="@/assets/images/logo-dark.svg"
        :alt="`${t('company.name')} logo.`"
      />
    </span>
    <span v-else>
      <img
        v-if="monogram"
        class="logo logo--monogram"
        src="@/assets/images/monogram-light.svg"
        :alt="`${t('company.name')} logo.`"
      />
      <img
        v-else
        class="logo"
        src="@/assets/images/logo-light.svg"
        :alt="`${t('company.name')} logo.`"
      />
    </span>
  </span>
</template>

<script lang="ts" setup>
import { useDark } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

defineOptions({
  name: 'LogoComponent'
})

const router = useRouter()
const isDark = useDark()
const { t } = useI18n()

const handleLogoClick = () => {
  const currentRoutePath = router.currentRoute.value.path

  if (currentRoutePath.includes('/auth/') && typeof window !== 'undefined') {
    window.open('https://spaces.fm', '_blank')
  } else {
    router.push({
      name: 'main',
      query: {
        ...router.currentRoute.value.query,
        filter: 'shuffle'
      }
    })
  }
}

withDefaults(
  defineProps<{
    monogram?: boolean
  }>(),
  {
    monogram: false
  }
)
</script>

<style scoped lang="scss">
.logo {
  height: var(--size-logo-height);
  cursor: pointer;

  &--monogram {
    width: var(--size-monogram-size);
    height: var(--size-monogram-size);

    &:hover {
      transform: scale(1.1);
      transform-origin: center;
      transition: 0.3s ease-in-out;
    }
  }
}
</style>
